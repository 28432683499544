<!--商品分类頁-->
<template>
    <div class="prod-content">
        <el-row>
            <el-col :lg="3" class="hidden-sm-and-down">&nbsp;</el-col>
            <el-col :lg="4" class="hidden-sm-and-down" style="padding-right: 20px;">
                <el-row>
                    <el-collapse v-model="activeNames">
                        <el-collapse-item title="商品分類" name="1" style="font-size: 16px;">
                            <div>
                                <span class="categry-item" @click="handleCategoryClick({id:0,name:'ALL'})">ALL</span>
                            </div>
                            <div v-for="(item, index) in categoryList" :key="index">
                                <span class="categry-item" @click="handleCategoryClick(item)">{{item.name}}</span>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-row>
                <el-row style="margin-top: 20px">依價格篩選</el-row>
                <el-slider
                        v-model="priceRange" style="color: red"
                        range
                        :step="5"
                        :max="2000"
                        @input="handlePriceChange">
                </el-slider>
                <el-row type="flex" justify="start">
                    <el-col :lg="19" style="font-size: 14px;line-height: 28px;">價格: {{minPrice| filter_addPricePrefix}} — {{maxPrice| filter_addPricePrefix}}</el-col>
                    <el-col :lg="5">
                        <el-button type="danger" size="mini" plain @click="search">篩選</el-button>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :lg="14" :xs="24" :sm="24">
                <el-breadcrumb separator="/" style="line-height: 48px;height: 48px;margin-left: 10px;">
                    <el-breadcrumb-item :to="{ path: '/' }">首頁</el-breadcrumb-item>
                    <el-breadcrumb-item>商品分類</el-breadcrumb-item>
                    <el-breadcrumb-item>{{cateName}}</el-breadcrumb-item>
                </el-breadcrumb>
                <el-row type="flex" justify="space-between" style="margin: auto 10px;" >
                    <el-col :lg="12" class="hidden-sm-and-up">
                        <span class="el-dropdown-link" @click="drawerShowFlg=true">
                            <i class="el-icon-s-unfold"></i>篩選
                        </span>
                    </el-col>
                    <el-col :lg="24" align="right">
                        <el-dropdown trigger="click" @command="handleProductSort">
                            <span class="el-dropdown-link">
                                <i class="el-icon-sort"></i>排序
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="">預設排序</el-dropdown-item>
                                <el-dropdown-item command="sales-desc">依熱銷度</el-dropdown-item>
                                <el-dropdown-item command="add-desc">依最新項目</el-dropdown-item>
                                <el-dropdown-item command="price-asc">依價格:低至高</el-dropdown-item>
                                <el-dropdown-item command="price-desc">依價格:高至低</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>

                    </el-col>
                </el-row>
                <Cards :rowCls="8" ref="cards" />
            </el-col>
            <el-col :lg="3" class="hidden-sm-and-down">&nbsp;</el-col>
        </el-row>

        <!--drawer-->
        <el-row class="hidden-sm-and-up">
            <el-col :xs="24" :sm="24">
                <el-drawer :visible.sync="drawerShowFlg" direction="ltr" size="70%">
                    <div style="margin: 0px 20px;">
                        <el-row>
                            <el-collapse v-model="activeNames">
                                <el-collapse-item title="商品分類" name="1" style="font-size: 16px;">
                                    <div>
                                        <span class="categry-item" @click="handleCategoryClick({id:0,name:'ALL'})">ALL</span>
                                    </div>
                                    <div v-for="(item, index) in categoryList" :key="index">
                                        <span class="categry-item" @click="handleCategoryClick(item)">{{item.name}}</span>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </el-row>
                        <el-row style="margin-top: 20px">依價格篩選</el-row>
                        <el-slider
                                v-model="priceRange"
                                range
                                :step="5"
                                :max="2000"
                                @change="handlePriceChange">
                        </el-slider>
                        <el-row type="flex" justify="start">
                            <el-col :xs="19" :sm="19" style="font-size: 14px;line-height: 28px;">價格: {{minPrice| filter_addPricePrefix}} — {{maxPrice| filter_addPricePrefix}}</el-col>
                            <el-col :xs="5" :sm="5">
                                <el-button type="primary" size="mini" plain @click="search">篩選</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </el-drawer>
            </el-col>
        </el-row>
        <!--drawer -->
    </div>
</template>

<script>
    import Cards from '@/components/Cards.vue';
    import {GetGoodsCategoryList} from '@/request/api';

    export default {
        name: '',
        components: {Cards},
        data() {
            return {
                cid: 0,
                kw:'',
                cateName: 'ALL',
                activeNames: ['1'],
                categoryList: [],
                minPrice: 199,
                maxPrice: 2000,
                priceRange: [199, 2000],
                drawerShowFlg: false,
            }
        },
        methods: {
            handleProductSort(order) {
                this.drawerShowFlg = false;
                this.$refs.cards.sortGoodsList(order);
            },
            search() {
                this.drawerShowFlg = false;
                this.$refs.cards.getGoodsList(this.cid,this.kw);
            },
            handlePriceChange(range) {
                this.minPrice = range[0];
                this.maxPrice = range[1];
                this.$refs.cards.setPriceRange(range[0],range[1]);
            },
            handleCategoryClick(item) {
                this.cid = item.id;
                this.kw = '';
                this.cateName = item.name;
                this.drawerShowFlg = false;
                this.search();

            },
            getCategoryList() {
                let _this = this;
                GetGoodsCategoryList().then(res => {
                    if (res.code == 200) {
                        let array = res.data;
                        if (array.length > 0) {
                           // _this.categoryList = array[0].child;
                            _this.categoryList = array;
                            if (_this.cid > 0) {
                                this.cateName = _this.categoryList.find(item=>item.id ==_this.cid).name;
                            }
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            }
        }, mounted() {
            this.getCategoryList();
        }, watch: {
            $route() {
                if (this.$route.query.kw) {
                    this.cid=0;
                    this.kw = this.$route.query.kw;
                    this.search()
                }else{
                    this.cid=0;
                    this.kw = '';
                    this.search()
                }
            }
        },
    }
</script>
<style lang="less" scoped>
    .prod-content {
    }

    /deep/ .el-collapse-item__header {
        font-size: 16px;
        font-weight: bold;
    }

    .categry-item {
        padding: 10px 0px 10px 20px;
        font-size: 14px;
        cursor: pointer;
    }

    /deep/ .el-card__body {
        padding: 2px;
    }
    /deep/.el-slider__button{
        border-color: red;
    }/deep/.el-slider__bar{
         background-color: red;
     }
</style>
